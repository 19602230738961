import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import './Navbar.css';

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 100%)" 
    strokeLinecap="round"
    {...props}
  />
);

const MenuToggle = ({ toggle, isOpen }) => (
  <button className="menu-toggle-btn" onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
        animate={isOpen ? "open" : "closed"}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
        animate={isOpen ? "open" : "closed"}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
        animate={isOpen ? "open" : "closed"}
      />
    </svg>
  </button>
);

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); 
  const [currentLanguage, setCurrentLanguage] = useState('EN');
  const [isClosing, setIsClosing] = useState(false);
  const { t, i18n } = useTranslation();
  const dropdownRef = useRef();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLanguage(lang);
    closeDropdown(); 
  };

  const closeDropdown = () => {
    setIsClosing(true);
    setTimeout(() => {
      setDropdownOpen(false);
      setIsClosing(false);
    }, 300);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const listVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    closed: {
      opacity: 0,
      y: -30,
      transition: {
        duration: 0.3,
      },
    },
  };

  const itemVariants = {
    open: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: index * 0.3, 
      },
    }),
    closed: (index) => ({
      opacity: 0,
      y: 20, 
      transition: {
        duration: 0.3,
        delay: (index + 2) * 0.5, 
      },
    }),
  };

  return (
    <motion.nav 
      className="navbar" 
      initial={{ height: 50 }} 
      animate={{ height: menuOpen ? 50 : 50 }} 
      transition={{ duration: 0.3 }}
    >
      <div className="navbar-logo">
        <MenuToggle toggle={toggleMenu} isOpen={menuOpen} />
        <img src={require('../Assets/logo.png')} alt="Logo" />
      </div>

      <div className={`navbar-links ${menuOpen ? 'active' : ''}`}>
        <a href="#home">{t('navbar.home')}</a>
        <a href="#works">{t('navbar.works')}</a>
        <a href="#contact">{t('navbar.contact')}</a>
      </div>

      <div className={`language-dropdown ${dropdownOpen ? 'open' : ''}`} ref={dropdownRef}>
        <button className="language-btn" onClick={() => setDropdownOpen((prev) => !prev)}>
          <img src={require("../Assets/lang.png")} alt="Globe" className="globe-icon" />
          {currentLanguage}
        </button>

        {dropdownOpen && (
          <motion.ul 
            className="language-options" 
            initial="closed" 
            animate={isClosing ? "closed" : "open"} 
            exit="closed" 
            variants={listVariants}
          >
            <motion.li
              variants={itemVariants}
              custom={0}
              initial="closed"
              animate={dropdownOpen ? "open" : isClosing ? "closed" : "closed"} 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => changeLanguage('EN')}
            >
              English
            </motion.li>
            <motion.li
              variants={itemVariants}
              custom={1}
              initial="closed"
              animate={dropdownOpen ? "open" : isClosing ? "closed" : "closed"} 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => changeLanguage('DE')}
            >
              Deutsch
            </motion.li>
          </motion.ul>
        )}
      </div>
    </motion.nav>
  );
};

export default Navbar;
