import React from 'react';
import { motion } from 'framer-motion'; 
import './Projects.css'; 
import { useTranslation } from 'react-i18next';

const cardVariants = {
  offscreen: {
    y: 40,
    opacity: 0.3,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.2,
      duration: 0.8,
    },
  },
  exit: {
    y: -100,
    opacity: 0.8,
    transition: {
      duration: 0.5,
    },
  },
};

const underlineVariants = {
  offscreen: { width: 0, opacity: 0 },
  onscreen: {
    width: 51, 
    opacity: 1,
    transition: { 
      duration: 0.5, 
      ease: 'easeInOut' 
    },
  },
  exit: {
    width: 0,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};


const imageVariants = {
  offscreen: {
    scale: 0.9,
    opacity: 0.6,
  },
  onscreen: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.3,
      duration: 0.5,
    },
  },
  exit: {
    scale: 0.9,
    opacity: 0.6,
    transition: {
      duration: 0.5,
    },
  },
};

const imgVariants = {
  offscreen: {
    scale: 0.9,
    opacity: 0.6,
  },
  onscreen: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.3,
      duration: 0.5,
      delay: 0.15, 
    },
  },
  exit: {
    scale: 0.9,
    opacity: 0.6,
    transition: {
      duration: 0.5,
    },
  },
};

const Projects = () => {
  const { t } = useTranslation();

  return (
    <div className='proj'>
      <h1 className='titleP'>{t('projtitle.text')}</h1>

      <div className="projects-container">
        <motion.div
          className="project-item left"
          initial="offscreen"
          whileInView="onscreen"
          exit="exit"
          viewport={{ once: false, amount: 0.8 }}
          variants={cardVariants}
        >
          
          <motion.div className="project-logo" variants={imageVariants}>
            
            <motion.img
              src={require('../Assets/qmlogo.png')}
              alt="Qualimatrix"
              variants={imgVariants}
            />
          </motion.div>
          <div className="project-info">
            <h1>Qualimatrix</h1>
            <p>{t('project.quali')}</p>
            <motion.div className="underline" variants={underlineVariants}></motion.div>
          </div>
        </motion.div>

        <motion.div
          className="project-item right"
          initial="offscreen"
          whileInView="onscreen"
          exit="exit"
          viewport={{ once: false, amount: 0.8 }}
          variants={cardVariants}
        >
          <div className="project-info">
            <h1>Notdienst Management</h1>
            <p>{t('project.notdienst')}</p>
            <motion.div className="underline" variants={underlineVariants}></motion.div>
          </div>
          <motion.div className="project-logo" variants={imageVariants}>
            <motion.img
              className="imgicn"
              src={require('../Assets/notdienst.png')}
              alt="Notdienst"
              variants={imgVariants}
            />
          </motion.div>
        </motion.div>

        <motion.div
          className="project-item left"
          initial="offscreen"
          whileInView="onscreen"
          exit="exit"
          viewport={{ once: false, amount: 0.8 }}
          variants={cardVariants}
        >
          <motion.div className="project-logo" variants={imageVariants}>
            <motion.img
              className="imgicn"
              src={require('../Assets/migration.png')}
              alt="Migration"
              variants={imgVariants}
            />
          </motion.div>
          <div className="project-info">
            <h1>{t('project.title1')}</h1>
            <p>{t('project.migration')}</p>
            <motion.div className="underline" variants={underlineVariants}></motion.div>
          </div>
        </motion.div>

        <motion.div
          className="project-item right"
          initial="offscreen"
          whileInView="onscreen"
          exit="exit"
          viewport={{ once: false, amount: 0.8 }}
          variants={cardVariants}
        >
          <motion.div className="project-logo" variants={imageVariants}>
            <motion.img
              className="imgicn"
              src={require('../Assets/rollout.png')}
              alt="Rollout"
              variants={imgVariants}
            />
          </motion.div>
          <div className="project-info">
            <h1>{t('project.title2')}</h1>
            <p>{t('project.rollout')}</p>
            <motion.div className="underline" variants={underlineVariants}></motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Projects;
