import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './App.css'; 
import Navbar from './Components/Navbar'; 
import Goals from './Components/Goals';
import Review from './Components/Review';
import Projects from './Components/Projects';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import './Components/i18n';

const App = () => {
  const [fillWidth, setFillWidth] = useState(0); 

 
  const titleVariants = {
    hidden: { y: '30%', opacity: 0, scale:0.95 }, 
    visible: { y: '0%', opacity: 1, scale:1 }, 
  };

 
  useEffect(() => {
    const timer = setTimeout(() => {
      setFillWidth(100); 
    }, 1200); 

    return () => clearTimeout(timer); 
  }, []);

  return (
    <div>
      <div className="landing-page" id="home">
        <Navbar />
        <div className="landing-page-content">
          
       
          <div className="clip-container first-line">
            <motion.h1 
              className='head'
              variants={titleVariants}
              initial="hidden"
              animate="visible"
              transition={{
                duration: 0.4,
                ease: 'easeOut',
                delay: 0.2,
              }}
            >
              Manage{'  '}
            </motion.h1>
            <motion.h1 
              className='head knw' 
              variants={titleVariants}
              initial="hidden"
              animate="visible"
              transition={{
                duration: 0.4,
                ease: 'easeOut',
                delay: 0.4, 
              }}
            >
              Knowledge,
            </motion.h1>
          </div>

        
          <div className="clip-container second-line" style={{ display: 'flex', alignItems: 'center' }}>
            <motion.h1 className='head' style={{ position: 'relative', margin: 0 }}>
              <motion.span
                className='box'
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  overflow: 'hidden',
                  zIndex: 1,
                }}
              >
                <motion.div
                  style={{
                    backgroundColor: '#7094BE',
                    height: '100%', // Match the height of the text
                    width: `${fillWidth}%`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    transition: 'width 0.3s ease',
                    zIndex: -1,
                  }}
                  initial={{ width: 0 }}
                  animate={{ width: fillWidth === 100 ? '100%' : '0%' }} // Animate to full width
                />
                <motion.span
                  className='text'
                  variants={titleVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    duration: 0.4,
                    ease: 'easeOut',
                    delay: 0.6, // "Develop" rises after "Knowledge"
                  }}
                  style={{ position: 'relative', zIndex: 1 }} // Ensure text is on top
                >
                  <motion.div
                    variants={titleVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{
                      duration: 0.4,
                      ease: 'easeOut',
                      delay: 0.8,
                    }}
                  >
                    Develop
                  </motion.div>
                </motion.span>
              </motion.span>
            </motion.h1>
            <motion.h1
              className='head sol' 
              variants={titleVariants}
              initial="hidden"
              animate="visible"
              transition={{
                duration: 0.4,
                ease: 'easeOut',
                delay: 1, 
              }}
              style={{ margin: 0, marginLeft: '10px' }} 
            >
              Solutions.
            </motion.h1>
          </div>
        </div>
      </div>
      
      <Goals />
      
      <div id="works">
      <Projects />
      </div>
      <div id="contact">
      <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default App;
