import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  EN: {
    translation: {
      navbar: {
        "home": "Home",
        "works": "Works",
        "contact": "Contact",
        language: "Language",
      },
      goals: {
        "title":"What we do",
        "save": "Save your time through easy information access",
        "focus": "Focus on your processes for faster and effective delivery",
        "infra": "Optimize your information infrastructure",
        "dev": "Re-develop your legacy solution to a modern web-based tool",
        "connect": "Connect different systems through interface development",
        "team": "Improve teams' collaboration through effective tools and training programs",
      },
      projtitle : {
        "text": "Projects we have completed...",
      },
      project:{
        "quali":"Development of a qualification matrix to provide a global industrial customer with an overview of who can do what, how well and what training needs there are.",
        "notdienst":"Development of a SharePoint portal to improve the emergency service for a medical service provider where employees and area managers can request and book each other for replacement.",
        "migration":"Migration of a legacy Access-Database to SQL Server for a growing medium-sized company in order to access the data faster and more securely.",
        "rollout":"Development of a Database for a large industrial producer to make sure, every User, Computer & Server has after the roll-out of new hardware the same access to their software then before.",
        "title1":"Database Migration",
        "title2":"Rollout Database",
      },
      contact: {
        "heading": "Interested in working with us?",
        "start": "Get Started",
        "tell": "Tell us more",
        "submit": "Submit",
        "Cname": "Company Name",
        "Fname": "First Name*",
        "Lname":"Last Name*",
        "email": "Your work email*",
        "Tproj":"Project Type*",
        "ProjText":"What type of Project are you interested in?",
        "DeProj":"Provide the description of your Project*",
        "ProjDesc":"Project Description",
        "msgsent":"🎉 Your message has been sent successfully!",
        "privacyNotice": "If you send us inquiries via contact form, we will collect your request data including the contact details from the inquiry form in order to process the request and in case of follow-up questions with us stored and processed. Your data will be used exclusively for the purpose of answering and processing your question. The data processing takes place here according to Art. 6 Sec. 1 lit. f GDPR on the basis of a legitimate interest. In the event of fraud detection or to detect misuse, for example to uncover hacker attacks, we reserve the right to store your network data for a short period of time and only for this purpose, not exceeding 30 days, unless a longer retention period is justified."
      },

    
      
    }
  },
  DE: {
    translation: {
      navbar: {
        "home": "Startseite",
        "works": "Projekte",
        "contact": "Kontakt",
        language: "Sprache",
      },
      goals: {
        "title": "Wir unterstützen Sie dabei...",
        "save": "Informationen zu finden statt Zeit mit Suchen verschwenden",
        "focus": "Prozesse zu fokussieren um schneller und effektiver zu werden",
        "infra": "Ihre Informations-Infrastruktur zu optimieren",
        "dev": "Ihre bestehenden Lösungen zu web-basierten Werkzeugen zu transformieren",
        "connect": "Verschiedene Systeme durch funktionale Schnittstellen verbinden",
        "team": "Die Zusammenarbeit durch geeignete Werkzeuge und Mitarbeiter-Training zu verbessern ",
        
      },
      projtitle : {
        "text": "Erfolgreich abgeschlossene Projekte...",
      },
      project:{
        "quali":"Entwicklung einer Qualifikations-Matrix um einem weltweit tätigen Industriekunden den Überblick zu verschaffen, Wer Was Wie gut kann und Welchen Trainingsbedarf es gibt.",
        "notdienst":"Entwicklung eines SharePoint-Portals zur Organisation des Notdienstes für einen medizinischen Dienstleister bei dem Mitarbeiter und Gebietsleitungen sich gegenseitig für Vertretungen anfragen und buchen können.",
        "migration":"Migration einer Access-Datenbank nach SQL Server für ein wachsendes mittelständisches Unternehmen, um auf die Daten schneller und sicherer zugreifen zu können.",
        "rollout":"Erstellung einer Datenbank-Lösung für einen großen Industriebetrieb damit jeder Benutzer, Computer und Server nach der Einführung neuer Hardware den gleichen Zugriff auf seine Software hat wie zuvor.",
        "title1":"Datenbanken Migrieren",
        "title2":"Rollout Datenbank",
      },
      contact: {
        heading: "Kontaktieren Sie uns",
        "start": "hier",
        "tell": "Erzählen Sie uns mehr",
        "submit": "Senden",
        "Cname": "Name der Firma",
        "Fname": "Vorname*",
        "Lname":"Nachname*",
        "email": "Ihre geschäftliche E-Mail*",
        "Tproj":"Art des Projekts*",
        "ProjText":"Für welche Art von Projekt interessieren Sie sich?",
        "DeProj":"Geben Sie eine kurze Beschreibung Ihres Projekts an*",
        "ProjDesc":"Projektbeschreibung",
        "msgsent":"🎉 Ihre Anfrage wurde erfolgreich gensendet!",
        "privacyNotice": "Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert und verarbeitet. Ihre Daten werden ausschließlich zweckgebunden zur Beantwortung und Bearbeitung Ihrer Frage genutzt. Die Datenverarbeitung erfolgt hier nach Art. 6 Abs. S. 1 lit. f DSGVO auf Grundlage des berechtigten Interesses. Für den Fall der Betrugserkennung oder zur Erkennung von Missbrauch, etwa zum Aufdecken von Hackerangriffen, behalten wir uns das Recht vor, Ihre Netzwerkdaten für einen kurzen Zeitraum und nur zu diesem Zweck zu speichern, der 30 Tage nicht überschreitet, außer ein Anlass rechtfertigt eine längere Aufbewahrung."
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "EN",
    fallbackLng: "EN",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
