import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Contact.css';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com'; 

const Contact = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const formRef = useRef(null);
  const formEl = useRef(null); 
  const [formSubmitted, setFormSubmitted] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(''); 

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target) && isVisible) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const sendEmail = (e) => {
    e.preventDefault();
  
    // Form validation
    const formData = new FormData(formEl.current);
    const firstName = formData.get('first_name');
    const lastName = formData.get('last_name');
    const email = formData.get('email');
    const projectDesc = formData.get('project_description');
    
    if (!firstName || !lastName || !email || !projectDesc) {
      setErrorMessage('Please fill all required fields.');
      return;
    }
  
    setErrorMessage(''); 
  
    emailjs
      .sendForm(
        'service_wnjpxet', 
        'template_pb4gwpu', 
        formEl.current,
        'zO0gzrSpxLDYzEOB_'
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormSubmitted(true);
          e.target.reset(); 
          setTimeout(() => setFormSubmitted(false), 3000); 
          
          return emailjs.send('service_wnjpxet', 'template_08vkivh', {
            to_email: email,
            first_name: firstName, 
            last_name: lastName, 
          }, 'zO0gzrSpxLDYzEOB_');
        },
        (error) => {
          console.log(error.text);
        }
      )
      .then((replyResult) => {
        console.log('Auto-reply sent:', replyResult.text);
        setTimeout(() => setFormSubmitted(false), 3000);
      })
      .catch((error) => {
        console.log('Error sending auto-reply:', error.text);
      });
  };
  
  return (
    <div className="form-section" ref={formRef}>
      <motion.div className="form-header">
        <motion.h1
          initial={{ marginTop: '-2.5rem' }}
          animate={{ marginTop: isVisible ? '0rem' : '-2.5rem' }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          {t('contact.heading')}
        </motion.h1>
        <motion.button
          className={`get-started-btn ${isVisible ? 'disabled' : ''}`}
          onClick={handleClick}
          initial={{ translateY: '10%', translateX: '-50%' }}
          animate={{
            top: isVisible ? '100%' : '50%',
            translateY: isVisible ? '-90%' : '-50%',
            position: 'absolute',
          }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          disabled={isVisible}
        >
          {isVisible ? t('contact.tell') : t('contact.start')}
        </motion.button>
      </motion.div>

      <motion.div
        className="form-body"
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: isVisible ? 1 : 0, height: isVisible ? 'auto' : 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <form className="contact-form" ref={formEl} onSubmit={sendEmail}>
          <input type="text" name="company" placeholder={t('contact.Cname')} className="form-input comp" />
          <div className="name-inputs">
            <input type="text" name="first_name" placeholder={t('contact.Fname')} className="form-input half-width" />
            <input type="text" name="last_name" placeholder={t('contact.Lname')} className="form-input half-width" />
          </div>
          <input type="email" name="email" placeholder={t('contact.email')} className="form-input email" />

          <label htmlFor="project-type">{t('contact.Tproj')}</label>
          <input type="text" name="project_type" placeholder={t('contact.ProjText')} className="form-input" />

          <label htmlFor="project-description">{t('contact.DeProj')}</label>
          <textarea name="project_description" placeholder={t('contact.ProjDesc')} className="form-input textarea desc"></textarea>

          <button type="submit" className="submit-btn">{t('contact.submit')}</button>
        </form>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <AnimatePresence>
          {formSubmitted && (
            <motion.div
              className="feedback-message"
              initial={{ opacity: 0, y: 50, scale: 0.3 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
              transition={{ duration: 0.5 }}
            >
              {t('contact.msgsent')}
            </motion.div>
          )}
        </AnimatePresence>
        <p className="privacy-notice">
        {t('contact.privacyNotice')}
       </p>
      </motion.div>
    </div>
  );
};

export default Contact;
