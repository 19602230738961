import React, { useState } from 'react';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [fade, setFade] = useState(false);

    const handleNavigate = (href) => {
        setFade(true);
        setTimeout(() => {
            window.location.href = href;
        }, 500); 
    };
  return (
    <footer className="footer">
      <div className="footer-left">
        <img src={require('../Assets/brain3tagline.png')} alt="Brand Logo" className="brand-logo" />
        <img src={require('../Assets/MSPartner.png')} alt="Microsoft Partner" className="small-pic" />
      </div>

      <div className="footer-right">
        <div className="footer-section">
          <img src={require('../Assets/phon.png')} alt="Phone Icon" className="icon" />
          <p>+49 (0)9905-70 70 007</p>
        </div>
        <div className="footer-section">
          <img src={require('../Assets/mail.png')} alt="Mail Icon" className="icon" />
          <p>service@brain3.de</p>
        </div>
        <div className="footer-buttons-container">
         <button onClick={() => handleNavigate('impressum.html')} className="footer-btn ripple">Impressum</button>
         <button onClick={() => handleNavigate('datenschutz.html')} className="footer-btn">Datenschutz</button>        
       </div>
      </div>
      <p className='rights'>©{currentYear} brain3. All rights reserved </p>
    </footer>
  );
};

export default Footer;
