import React from 'react';
import './Goals.css';
import { useTranslation } from 'react-i18next';
import { easeIn, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const GoalItem = ({ goal, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.5, 
    triggerOnce: false,
  });

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2, 
        duration: 0.6,
      },
    }),
    exit: (i) => ({
      opacity: 0,
      x: -20,
      transition: {
        delay: i * 0.2,
        duration: 0.8,
      },
    }),
  };

  
  const iconVariants = {
    hidden: { x:-20, opacity: 0.7 },
    visible: (i) => ({
      x:0,
      opacity: 1,
      transition: {
        delay: i * 0.2 + 0.2,  
        duration: 0.5,
      },
    }),
    exit: { x:-20, opacity: 0.7 },
  };

  return (
    <motion.div
      className="goal-item"
      custom={index}
      initial="hidden"
      animate={inView ? 'visible' : 'exit'}
      exit="exit"
      viewport={{ once: false, amount: 0.5 }}
      variants={itemVariants}
    >
      <motion.div 
        className="goal-icon" 
        custom={index}
        variants={iconVariants}  
        ref={ref}
        viewport={{ once: false, amount: 0.2 }}
      >
        <img className="icon" src={require(`../Assets/${goal.icon}`)} alt="Icon" />
      </motion.div>

      <p className="goal-text">{goal.text}</p>
    </motion.div>
  );
};


const Goals = () => {
  const { t } = useTranslation();

  const goals = [
    { id: 1, text: t('goals.save'), icon: 'info.png' },
    { id: 2, text: t('goals.focus'), icon: 'focus.png' },
    { id: 3, text: t('goals.infra'), icon: 'infra.png' },
    { id: 4, text: t('goals.dev'), icon: 'redev.png' },
    { id: 5, text: t('goals.connect'), icon: 'conn.png' },
    { id: 6, text: t('goals.team'), icon: 'team.png' },
  ];

  return (
    <div className="goals-container">
      <h2 className="title">{t('goals.title')}</h2>
      <motion.div className="goals-grid">
        {goals.map((goal, index) => (
          <GoalItem key={goal.id} goal={goal} index={index} />
        ))}
      </motion.div>
    </div>
  );
};

export default Goals;
